import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { AiOutlineClose } from "react-icons/ai";

import {
  nextSong,
  prevSong,
  playPause,
  setActiveSong,
} from "../../redux/features/playerSlice";
import Controls from "./Controls";
import Player from "./Player";
import Seekbar from "./Seekbar";
import Track from "./Track";
import VolumeBar from "./VolumeBar";

const MusicPlayer = () => {
  const { activeSong, currentSongs, currentIndex, isActive, isPlaying } =
    useSelector((state) => state.player);
  const [duration, setDuration] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [appTime, setAppTime] = useState(0);
  const [volume, setVolume] = useState(0.3);
  const [repeat, setRepeat] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentSongs.length) dispatch(playPause(true));
  }, [currentIndex]);

  const handlePlayPause = () => {
    if (!isActive) return;

    if (isPlaying) {
      dispatch(playPause(false));
    } else {
      dispatch(playPause(true));
    }
  };

  const handleNextSong = () => {
    dispatch(playPause(false));

    if (!shuffle) {
      dispatch(nextSong((currentIndex + 1) % currentSongs.length));
    } else {
      dispatch(nextSong(Math.floor(Math.random() * currentSongs.length)));
    }
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
  };

  const handlePrevSong = () => {
    if (currentIndex === 0) {
      dispatch(prevSong(currentSongs.length - 1));
    } else if (shuffle) {
      dispatch(prevSong(Math.floor(Math.random() * currentSongs.length)));
    } else {
      dispatch(prevSong(currentIndex - 1));
    }
  };

  const handleClose = () => {
    dispatch(playPause(false));
    dispatch(setActiveSong({}));
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (isActive) {
        switch (event.key) {
          case " ":
            handlePlayPause(); // Space key to play/pause
            break;
          case "ArrowRight":
            handleNextSong(); // Right arrow key for next track
            break;
          case "ArrowLeft":
            handlePrevSong(); // Left arrow key for previous track
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isActive, handlePlayPause, handleNextSong, handlePrevSong]);

  return (
    <div className="row align-items-center justify-content-center">
      <Helmet>
        <title>{activeSong?.name || "Music Player"}</title>
        {activeSong?.poster && (
          <meta property="og:image" content={activeSong.poster} />
        )}
      </Helmet>
      <div className="col-md-12 d-flex fixed-bottom justify-content-center align-items-center bg-player z-3">
        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-3 justify-content-center d-flex px-2 mb-2">
          <Track
            isPlaying={isPlaying}
            isActive={isActive}
            activeSong={activeSong}
          />
        </div>
        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 mb-2 d-flex flex-column align-items-center">
          <Controls
            isPlaying={isPlaying}
            isActive={isActive}
            repeat={repeat}
            setRepeat={setRepeat}
            shuffle={shuffle}
            setShuffle={setShuffle}
            currentSongs={currentSongs}
            handlePlayPause={handlePlayPause}
            handlePrevSong={handlePrevSong}
            handleNextSong={handleNextSong}
            setVolume={setVolume}
          />
          <Seekbar
            value={appTime}
            min="0"
            max={duration}
            onInput={(event) => setSeekTime(event.target.value)}
            setSeekTime={setSeekTime}
            appTime={appTime}
          />
          <Player
            activeSong={activeSong}
            volume={volume}
            isPlaying={isPlaying}
            seekTime={seekTime}
            repeat={repeat}
            currentIndex={currentIndex}
            onEnded={handleNextSong}
            onTimeUpdate={(event) => setAppTime(event.target.currentTime)}
            onLoadedData={(event) => setDuration(event.target.duration)}
          />
        </div>
        <div className="col-lg-2 col-md-12 d-flex align-items-center justify-content-center mb-2">
          <VolumeBar
            value={volume}
            min="0"
            max="1"
            onChange={handleVolumeChange}
          />
        </div>
        <div className="col-lg-1 d-none d-md-block align-items-start justify-content-center mb-2">
          <button className="close-button" onClick={handleClose}>
            <AiOutlineClose size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;
