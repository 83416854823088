import React, { useState } from "react";
import { addSub } from "../api";
import { toast } from "react-toastify";
import PORT from "../const";

const FooterComponent = () => {
  const [email, setEmail] = useState([]);
  const [firstname, setFname] = useState([]);
  const [lastname, setLname] = useState([]);

  //   add subscribers
  const sendForm = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    var data = {
      email: email,
      first_name: firstname,
      last_name: lastname,
    };

    try {
      const res = await addSub(data);
      // console.log("email sent");
      // console.log(res);
      toast.success("Subscription added successfully");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        //   setError(error.response.data.detail);
        // console.log(error);
        toast.error(error.response.data.message); // Set the error message in state
      }
    }
    

    // Clear the form
    setEmail("");
    setFname("");
    setLname("");
  };

  return (
    <div className="row mt-5 mx-3  mb-4">
      <div className="col-md-12  h-100 br-15 cus-dark" id="connect">
        <div className="row mt-3 mb-3">
          <p className="text-center text-white mb-4 fs-5 quicksand">
            Sign up with your E-mail address to receive updates
          </p>
          <div className="col-md-12 d-flex justify-content-center align-items-center mb-3">
            <form
              onSubmit={sendForm}
              method="POS"
              className="row g-3 justify-content-center align-content-center"
            >
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control br-15"
                  id="firstName"
                  placeholder="First Name"
                  name="firstName"
                  value={firstname}
                  onChange={(e) => setFname(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control br-15"
                  placeholder="Last Name"
                  id="lastName"
                  name="lastName"
                  value={lastname}
                  onChange={(e) => setLname(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3">
                <input
                  type="email"
                  placeholder="Email address"
                  className="form-control br-15"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  required
                />
              </div>
              <div className="col-md-3 mt-3  d-flex">
                <input
                  type="submit"
                  className="btn btn-dark border-white border-1 btn-md rounded-pill w-100"
                  value="Join Now"
                />
              </div>
            </form>
          </div>
        </div>
        <hr className="text-white" />
        <div className="row m-auto d-flex flex-row justify-content-center align-items-center">
          <div className="col-md-2 d-block m-auto p-auto  logo">
            <a
              href="https://lavasa.christuniversity.in/"
              className="text-decoration-none"
            >
              <img src="./assets/img/4.png" alt="" className="img-fluid" />
            </a>
          </div>
          <div className="col-md-3 m-auto d-block h-50 address p-4">
            <div className="text-white mt-4 text-footer ">
              <span className="text-custom">
                CHRIST (Deemed to be University)
              </span>

              <p>Pune Lavasa Campus - 'The Hub of Analytics'</p>

              <p>Christ University Road, 30 Valor CourtAt Post: Dasve</p>

              <p>Lavasa,Taluka: MulshiPune 412112, Maharashtra.</p>
            </div>
          </div>
        </div>
        <hr className="text-white" />
        <div className="row">
          <div className="col-md-12  d-flex text-white-50 pb-3 justify-content-around align-items-center">
            <div className="float-start">
              © Voice of Lavasa, All Right Reserved.
            </div>
            <div className="text-white mx-2">
              <a
                href="https://www.instagram.com/thebuzzvoiceoflavasa/"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fab fa-instagram mx-2 text-white"></i>
              </a>
              <a
                href="https://www.youtube.com/@ChristUniversityLavasa"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fab fa-youtube text-white mx-2"></i>
              </a>
            </div>
            <div className="float-end">
              Developed By{" "}
              <a
                href="http://christinfotech.org/"
                className="text-decoration-none"
              >
                CHRIST Infotech
              </a>
              <br />
              Maintained by{" "}
              <a
                href="https://lavasa.christuniversity.in/center/C/CDL"
                className="text-decoration-none"
              >
                Center for Digital Learning
              </a>
            </div>
            <div className="float-end">
              <a
                href={`${PORT}login/`}
                target="_blank"
                className="d-flex text-decoration-none text-white"
              >
                <i className="fa-solid fa-user"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
